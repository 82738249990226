import React, { Component, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  title?: string | null;
  className?: string;
}

class Title extends Component<Props> {
  render(): ReactNode {
    if (!this.props.title) {
      return null;
    }
    return (
      <h1
        className={`flex justify-center m-auto
      tablet:mx-16
      laptop-standard:mx-auto
      laptop-standard:max-w-6xl
      text-center
      ${this.props.className ?? ''}`}
      >
        {ReactHtmlParser(this.props.title)}
      </h1>
    );
  }
}

export default Title;
