import React, { Component, ReactNode } from 'react';

interface Props {
  className?: string;
}

class Container extends Component<Props> {
  render(): ReactNode {
    return (
      <div className={`container max-width ${this.props.className ?? ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Container;
