import React, { Component, ReactNode } from 'react';
import Button from '../button';
import dots from '../../images/icons/Share_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { PlayState, Tween } from 'react-gsap';

interface Props {
  mailSubject: string;
  mailBody: string;
}

interface State {
  location: string;
  showShareButtons: PlayState;
}

const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';
const LINKEDIN_SHARE_URL = 'https://www.linkedin.com/sharing/share-offsite/?url=';
const TWITTER_SHARE_URL = 'http://www.twitter.com/share?url=';

const SIZE_SOCIAL_BUTTON_DESKTOP = 'lg';
const SIZE_SOCIAL_BUTTON_MOBILE = '2x';

const ANIMATION = {
  DURATION: 2,
  STAGGER: 0.5,
  EASE: 'back',
};

class ShareAnimatedButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      location: '',
      showShareButtons: PlayState.stop,
    };
  }

  componentDidMount(): void {
    this.setCurrentLocation();
    window.addEventListener('location', this.setCurrentLocation);
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    window.removeEventListener('location', this.setCurrentLocation);
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (): void => {
    if (this.state.showShareButtons) {
      this.setState({ showShareButtons: PlayState.reverse });
    }
  };

  setCurrentLocation(): void {
    this.setState({ 'location': window.location.href });
  }

  render(): ReactNode {
    const body = `${this.props.mailBody} ${this.state.location}`;

    const emailLink = `mailto:?subject=${this.props.mailSubject}&body=${encodeURIComponent(body)}`;
    const facebookLink = `${FACEBOOK_SHARE_URL}${this.state.location}`;
    const linkedinLink = `${LINKEDIN_SHARE_URL}${this.state.location}`;
    const twitterLink = `${TWITTER_SHARE_URL}${this.state.location}`;

    return (
      <>
        <div className="share-article-button desktop-only">
          <div style={{ position: 'relative', cursor: 'pointer' }}>
            <Button
              buttonTitle="Share"
              styles="shareAnimatedButton"
              icon={dots}
              contentType="externalLink"
              onClick={(event) => {
                const { showShareButtons } = this.state;
                event.stopPropagation();
                this.setState({
                  showShareButtons:
                    showShareButtons === PlayState.stop || showShareButtons === PlayState.reverse
                      ? PlayState.play
                      : PlayState.reverse,
                });
              }}
            />
            <>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: -40, y: -40, opacity: 0 },
                    { x: -40, y: 60, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(facebookLink)}>
                  <FontAwesomeIcon icon={faFacebookF} size={SIZE_SOCIAL_BUTTON_DESKTOP} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: -50, y: -50, opacity: 0 },
                    { x: -80, y: -15, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(linkedinLink)}>
                  <FontAwesomeIcon icon={faLinkedin} size={SIZE_SOCIAL_BUTTON_DESKTOP} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: -50, y: -50, opacity: 0 },
                    { x: -75, y: 30, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(twitterLink)}>
                  <FontAwesomeIcon icon={faTwitter} size={SIZE_SOCIAL_BUTTON_DESKTOP} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: -40, y: -40, opacity: 0 },
                    { x: -50, y: -50, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(emailLink)}>
                  <FontAwesomeIcon icon={faEnvelope} size={SIZE_SOCIAL_BUTTON_DESKTOP} />
                </div>
              </Tween>
            </>
          </div>
        </div>
        <div className="share-article-button mobile-only">
          <div style={{ position: 'relative', cursor: 'pointer' }}>
            <Button
              buttonTitle="Share"
              styles="shareAnimatedButton"
              icon={dots}
              contentType="externalLink"
              onClick={(event) => {
                const { showShareButtons } = this.state;
                event.stopPropagation();
                this.setState({
                  showShareButtons:
                    showShareButtons === PlayState.stop || showShareButtons === PlayState.reverse
                      ? PlayState.play
                      : PlayState.reverse,
                });
              }}
            />
            <>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: 40, y: -40, opacity: 0 },
                    { x: 170, y: 30, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(facebookLink)}>
                  <FontAwesomeIcon icon={faFacebookF} size={SIZE_SOCIAL_BUTTON_MOBILE} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: 20, y: -35, opacity: 0 },
                    { x: 170, y: -30, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(linkedinLink)}>
                  <FontAwesomeIcon icon={faLinkedin} size={SIZE_SOCIAL_BUTTON_MOBILE} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: 50, y: -30, opacity: 0 },
                    { x: 100, y: 30, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(twitterLink)}>
                  <FontAwesomeIcon icon={faTwitter} size={SIZE_SOCIAL_BUTTON_MOBILE} />
                </div>
              </Tween>
              <Tween
                to={{ zIndex: 10 }}
                playState={this.state.showShareButtons}
                duration={ANIMATION.DURATION}
                ease={ANIMATION.EASE}
                motionPath={{
                  path: [
                    { x: 50, y: -20, opacity: 0 },
                    { x: 100, y: -30, opacity: 1 },
                  ],
                  type: 'cuadratic',
                }}
              >
                <div className="social-icon" onClick={() => window.open(emailLink)}>
                  <FontAwesomeIcon icon={faEnvelope} size={SIZE_SOCIAL_BUTTON_MOBILE} />
                </div>
              </Tween>
            </>
          </div>
        </div>
      </>
    );
  }
}

export default ShareAnimatedButton;
